import React from 'react';
import {graphql, Link} from 'gatsby';
import Main from '@components/layout/main';
import {BlogEntryObject} from '@utils/types';
import {ArticleSection} from '@utils/types';
import ArticleTextCard from '@components/article/article-text-card';
import ArticleImageCard from '@components/article/article-image-card';
import TextShadow from '@components/elements/text-shadow';
import SlimHeader from '@components/layout/slim-header';
import Meta from '@components/layout/meta';
import {filter, head} from 'lodash';
import Card from '@components/elements/card';

export default function BlogEntry({data}) {
    const blogEntry: BlogEntryObject = data.strapiBlogEntry;
    const blogEdges: {
        node: {identifier: string};
        next: {identifier: string; headline: string};
    }[] = data.allStrapiBlogEntry.edges;

    const blogEdge = head(
        filter(blogEdges, (e) => e.node.identifier == blogEntry.identifier),
    );

    return (
        <>
            <Meta
                title='Bündnis #noIAA'
                description={
                    'Klima vor Profit - Mobilitätswende jetzt! ' +
                    blogEntry.headline
                }
                type='article'
                path={`/blog/${blogEntry.identifier}`}
            />
            <SlimHeader backTo='/#blog' />
            <Main slimHeader>
                <h1 className='relative max-w-3xl mx-auto mt-8 mb-4 text-3xl text-center md:mt-4 lg:mt-0 rubik'>
                    <TextShadow text={blogEntry.headline} />
                </h1>
                <article className='w-full space-y-4 centering-col'>
                    {blogEntry.article.map(
                        (node: ArticleSection, index: number) => {
                            switch (node.strapi_component) {
                                case 'article-section.text':
                                    return (
                                        <ArticleTextCard
                                            key={index}
                                            node={node}
                                        />
                                    );
                                case 'article-section.image':
                                    return (
                                        <ArticleImageCard
                                            key={index}
                                            node={node}
                                        />
                                    );
                                default:
                                    return <React.Fragment />;
                            }
                        },
                    )}
                </article>
                {blogEdge.next && (
                    <>
                        <div className='my-4 text-xl text-left crimson font-weight-500'>
                            Nächster Artikel:
                        </div>
                        <div
                            style={{width: '36rem'}}
                            className='max-w-full space-y-3 centering-col'
                        >
                            <Link
                                className='w-full cursor-pointer group'
                                to={`/blog/${blogEdge.next.identifier}`}
                                key={blogEdge.next.identifier}
                            >
                                <Card variant='yellow'>
                                    <div className='w-full mb-1 text-xl text-center text-gray-900 transition-colors duration-150 crimson font-weight-700 group-hover:text-noblue flex-row-top'>
                                        {blogEdge.next.headline}
                                    </div>
                                </Card>
                            </Link>
                        </div>
                    </>
                )}
            </Main>
        </>
    );
}

export const query = graphql`
    query($identifier: String!) {
        strapiBlogEntry(identifier: {eq: $identifier}) {
            headline
            author
            identifier
            article {
                strapi_component
                image {
                    url
                    width
                    height
                }
                copyright
                subtitle
                text
            }
        }
        allStrapiBlogEntry(sort: {fields: position}) {
            edges {
                node {
                    identifier
                }
                next {
                    identifier
                    headline
                }
            }
        }
    }
`;
